// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-pest-control-js": () => import("./../../../src/pages/pest-control.js" /* webpackChunkName: "component---src-pages-pest-control-js" */),
  "component---src-pages-strip-and-wax-js": () => import("./../../../src/pages/strip-and-wax.js" /* webpackChunkName: "component---src-pages-strip-and-wax-js" */),
  "component---src-pages-thank-you-page-js": () => import("./../../../src/pages/ThankYouPage.js" /* webpackChunkName: "component---src-pages-thank-you-page-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-other-page-js": () => import("./../../../src/templates/otherPage.js" /* webpackChunkName: "component---src-templates-other-page-js" */)
}

